import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectModel } from 'core-web/state';
import { above } from 'core-web/util/mediaqueries';
import colors from 'theme/config/colors';
import styled from 'theme/libs/styled';
import ArticleCard from 'theme/components/article/ArticleCard';
import CampaignCard from 'theme/components/campaigns/CampaignCard';
import ProductCard from 'theme/components/product/ProductCard';
import ProductGuide from 'theme/components/productguide/ProductGuide';

const Column = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Row = styled('div')`
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    ${above.md} {
        flex-wrap: nowrap;

        &:not(:last-child) {
            margin-bottom: 8px;
        }
    }

    ${above.lg} {
        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }
`;

const excludedProps = ['isWide', 'setNoMargin'];
const Content = styled('div', { shouldForwardProp: prop => excludedProps.indexOf(prop) === -1 })`
    width: ${({ isWide }) => (isWide ? 'calc(100%)' : 'calc(50% - 2px)')};
    margin-bottom: 4px;
    background-color: ${colors.white};

    ${above.md} {
        margin-bottom: 0px;
        width: ${({ isWide }) => (isWide ? 'calc(50% + 8px)' : '25%')};
    }
    ${above.lg} {
        width: ${({ isWide }) => (isWide ? 'calc(50% + 16px)' : '25%')};
    }

    &:not(:last-child) {
        margin-right: ${({ isWide, setNoMargin }) => (isWide || setNoMargin ? 0 : '4px')};

        ${above.md} {
            margin-right: 8px;
        }
        ${above.lg} {
            margin-right: 16px;
        }
    }
`;

const DoubleCampaignModule = ({ customer, products, modules, isTopRightWide, isBottomRightWide, articles }) => {
    const [allProducts, setAllProducts] = useState(products);

    useEffect(() => {
        const handlePriceLists = async products => {
            if (!products?.length) {
                return;
            }

            const { getProductPrices, pricelistSeed } = customer;

            if (pricelistSeed) {
                const productsFromPricelist = await getProductPrices(products);

                if (productsFromPricelist?.length && Array.isArray(productsFromPricelist)) {
                    setAllProducts(productsFromPricelist);

                    return;
                }
            }

            setAllProducts(products);
        };

        handlePriceLists(products);
    }, [products, customer?.pricelistSeed]);

    return (
        <Column>
            {modules.map((campaignRow, index) => (
                <Row key={index}>
                    {campaignRow.map((item, index) => {
                        const data = item.top_campaign_row || item.bottom_campaign_row;
                        const isRightWide = item.top_campaign_row ? isTopRightWide : isBottomRightWide;
                        const setNoMargin = index === 1 && (isRightWide || campaignRow.length > 3);
                        return (
                            <Content key={index} setNoMargin={setNoMargin} isWide={item.isWide}>
                                {data.selected_card === 'campaign' ? (
                                    <CampaignCard auto="format" isWide={item.isWide} data={data.campaign_data} />
                                ) : data.selected_card === 'product' && allProducts.length > 0 ? (
                                    <ProductCard
                                        overrideImg={data?.product_data}
                                        isWide={item.isWide}
                                        data={allProducts.find(p => p.objectID === data.product_data.product)}
                                        hideItems={['compare']}
                                        imageSizes={item.isWide ? ['90vw', '45vw'] : ['40vw', '20vw']}
                                    />
                                ) : data.selected_card === 'article' && articles.length > 0 ? (
                                    <ArticleCard
                                        auto="format"
                                        isWide={item.isWide}
                                        override={data.article_data}
                                        data={articles.find(a => a.external_id === data.article_data.article)}
                                    />
                                ) : item.selected_card === 'product_guide' ? (
                                    <ProductGuide isWide={item.isWide} data={data.product_guide_data} />
                                ) : null}
                            </Content>
                        );
                    })}
                </Row>
            ))}
        </Column>
    );
};

DoubleCampaignModule.propTypes = {
    articles: PropTypes.array.isRequired,
    customer: PropTypes.object.isRequired,
    isBottomRightWide: PropTypes.bool,
    isTopRightWide: PropTypes.bool,
    modules: PropTypes.array.isRequired,
    products: PropTypes.array.isRequired,
};

DoubleCampaignModule.defaultProps = {
    isBottomRightWide: false,
    isTopRightWide: false,
};

export default injectModel('customer')(DoubleCampaignModule);
